import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "state/hooks";
import { MentorshipPlanTemplate } from "types/mentorship-plan";
import {
  collapsedAllMonthsClicked,
  expandAllMonthsClicked,
  mentorshipMonthCollapsed,
  mentorshipMonthExpanded,
} from "../analytics/index.analytics";

type UseMentorshipPlanDetailsProps = {
  mentorshipPlanData: MentorshipPlanTemplate | undefined;
  templateForMonths: number;
};

const useMentorshipPlanDetails = ({
  mentorshipPlanData,
  templateForMonths,
}: UseMentorshipPlanDetailsProps) => {
  const ROLE = useAppSelector((state) => state.generalConfigs.join_role);
  const menteeData = useAppSelector((state) => state.planner.menteeData);
  const mentorData = useAppSelector((state) => state.planner.mentorData);

  const [expandedMonthsArray, setExpandedMonthsArray] = useState<number[]>([]);
  const [openTopicsSection, setOpenTopicsSection] = useState(false);

  const curriculumStats = useMemo(() => {
    return mentorshipPlanData?.months?.reduce(
      (acc, item) => {
        acc.sessions += item?.mentorPlanSessions?.length;
        acc.topics += item?.mentorPlanTopics?.length;
        acc.studyMaterials +=
          item?.mentorPlanAssignments?.length +
          item?.mentorPlanProjects?.length +
          item?.mentorPlanResources?.length;
        return acc;
      },
      { sessions: 0, topics: 0, studyMaterials: 0 } as {
        sessions: number;
        topics: number;
        studyMaterials: number;
      }
    );
  }, [mentorshipPlanData]);

  const defaultAnalyticsPayload = {
    mentee_email: menteeData?.email,
    mentor_email: mentorData?.email,
    user_email: ROLE === "mentor" ? mentorData.email : menteeData?.email,
    product_name: "Trial Doc",
    track_details: {
      candidate: menteeData?.email
        ? {
            email: menteeData?.email,
            recordId: menteeData?.id,
            name: menteeData?.candidateName,
          }
        : "Anonymous",
      mentor: {
        email: mentorData?.email,
        recordId: mentorData?.id,
        name: mentorData?.name,
      },
      curriculumData: {
        templateId: mentorshipPlanData?.recordId,
        title: mentorshipPlanData?.title,
        planDuration: mentorshipPlanData?.templateForMonth,
        totalSessions: curriculumStats?.sessions,
        totalSubTopics: curriculumStats?.topics,
        totalStudyMaterials: curriculumStats?.studyMaterials,
      },
      source: "Preplaced Meetings",
    },
  };

  useEffect(() => {
    if (expandedMonthsArray?.length === 1 && expandedMonthsArray?.includes(0))
      setOpenTopicsSection(true); // only for first month on forst loading
  }, []);

  const handleExpandAllMonths = () => {
    if (expandedMonthsArray?.length === mentorshipPlanData?.months?.length) {
      setExpandedMonthsArray([]);
      collapsedAllMonthsClicked(defaultAnalyticsPayload);
    } else {
      const array = Array.from(
        { length: mentorshipPlanData?.months?.length! },
        (_, i) => i
      );
      setExpandedMonthsArray(array);
      expandAllMonthsClicked(defaultAnalyticsPayload);
    }
  };

  const handleExpandedMonthsArray = (index: number) => {
    if (expandedMonthsArray?.length === 1) {
      if (expandedMonthsArray?.includes(index)) {
        setExpandedMonthsArray([]);
        mentorshipMonthCollapsed({
          ...defaultAnalyticsPayload,
          monthValue: index + 1,
        });
      } else {
        setExpandedMonthsArray([index]);
        mentorshipMonthExpanded({
          ...defaultAnalyticsPayload,
          monthValue: index + 1,
        });
      }
    } else if (expandedMonthsArray?.includes(index)) {
      setExpandedMonthsArray((prev) => prev.filter((item) => item !== index));
      mentorshipMonthCollapsed({
        ...defaultAnalyticsPayload,
        monthValue: index + 1,
      });
    } else {
      setExpandedMonthsArray((prev) => [...prev, index]);
      mentorshipMonthExpanded({
        ...defaultAnalyticsPayload,
        monthValue: index + 1,
      });
    }
    setOpenTopicsSection(false);
  };

  useEffect(() => {
    if (templateForMonths === 1) setExpandedMonthsArray([0]);
    else setExpandedMonthsArray([]);
  }, [templateForMonths]);

  return {
    curriculumStats,
    handleExpandAllMonths,
    expandedMonthsArray,
    handleExpandedMonthsArray,
    openTopicsSection,
    defaultAnalyticsPayload,
  };
};

export default useMentorshipPlanDetails;
