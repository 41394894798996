import WhiteDotIcon from "assets/icons/mentorship-plan/white-dot.svg";
import TemplateMonth from "./template-month/template-month";
import useMentorshipPlanDetails from "./use-mentorship-plan-template-details";
import { MentorshipPlanTemplate } from "types/mentorship-plan";

type MentorshipPlanTemplateDetailsProps = {
  mentorshipPlanData: MentorshipPlanTemplate | undefined;
  templateForMonths: number;
};

const MentorshipPlanTemplateDetails = ({
  mentorshipPlanData,
  templateForMonths,
}: MentorshipPlanTemplateDetailsProps) => {
  const {
    curriculumStats,
    handleExpandAllMonths,
    expandedMonthsArray,
    handleExpandedMonthsArray,
    openTopicsSection,
    defaultAnalyticsPayload,
  } = useMentorshipPlanDetails({
    mentorshipPlanData,
    templateForMonths,
  });
  return (
    <div className="space-y-7">
      <div className="hidden items-center justify-between md:flex">
        <div className="flex items-center gap-3">
          <p className="text-[13px] text-[#fff]">
            {curriculumStats?.sessions} Sessions
          </p>
          <img src={WhiteDotIcon} alt="dot.svg" />
          <p className="text-[13px] text-[#fff]">
            {curriculumStats?.topics} Topics
          </p>
          {curriculumStats?.studyMaterials ? (
            <>
              <img src={WhiteDotIcon} alt="dot.svg" />
              <p className="text-[13px] text-[#fff]">
                {curriculumStats?.studyMaterials} Study Material
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
        <button
          className="text-sm font-medium text-[#fff] underline"
          onClick={handleExpandAllMonths}
        >
          {expandedMonthsArray?.length === mentorshipPlanData?.months?.length
            ? "Collapsed All Months"
            : "Expand All Months"}
        </button>
      </div>

      <div className="space-y-5">
        {mentorshipPlanData?.months?.map((item, index) => {
          return (
            <TemplateMonth
              key={index}
              templateMonthData={item}
              index={index}
              expandedMonthsArray={expandedMonthsArray}
              handleExpandedMonthsArray={handleExpandedMonthsArray}
              openTopicsSection={openTopicsSection}
              defaultAnalyticsPayload={defaultAnalyticsPayload}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MentorshipPlanTemplateDetails;
