import { getMentorMentorshipPlans } from "api/mentorship-curriculm";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { setMentorMentorshipCurriculumData } from "state/planner/plannerReducer";
import {
  MentorshipCurriculumDataType,
  MentorshipPlanTemplate,
} from "types/mentorship-plan";

const useRightSide = () => {
  const dispatch = useAppDispatch();
  const mentorData = useAppSelector((state: any) => state.planner.mentorData);
  const [isCurriculumLoading, setIsCurriculumLoading] = useState(true);

  const [isCurriculumCompleted, setIsCurriculumCompleted] = useState(false);

  useEffect(() => {
    getMentorMentorshipPlans({ mentor_id: mentorData?.id })
      .then((data) => {
        const curriculumData: MentorshipCurriculumDataType = (
          data || []
        ).reduce(
          (acc: any, plan: MentorshipPlanTemplate) => {
            const progress = plan?.progress;
            if (plan?.templateForMonth === 1) {
              acc.OneMonth.mentorshipPlans.push(plan);
              if (progress === 100) acc.OneMonth.status = "Completed";
            } else if (plan?.templateForMonth === 3) {
              acc.ThreeMonths.mentorshipPlans.push(plan);
              if (progress === 100) acc.ThreeMonths.status = "Completed";
            } else if (plan?.templateForMonth === 6) {
              acc.SixMonths.mentorshipPlans.push(plan);
              if (progress === 100) acc.SixMonths.status = "Completed";
            }
            return acc;
          },
          {
            OneMonth: { mentorshipPlans: [], status: "" },
            ThreeMonths: { mentorshipPlans: [], status: "" },
            SixMonths: { mentorshipPlans: [], status: "" },
          } as MentorshipCurriculumDataType
        );
        dispatch(setMentorMentorshipCurriculumData(curriculumData));

        if (
          curriculumData?.SixMonths?.status === "Completed" &&
          curriculumData?.ThreeMonths?.status === "Completed" &&
          curriculumData?.OneMonth?.status === "Completed"
        )
          setIsCurriculumCompleted(true);
      })
      .finally(() => {
        setIsCurriculumLoading(false);
      });
  }, [mentorData]);

  return {
    isCurriculumCompleted,
    isCurriculumLoading,
  };
};

export default useRightSide;
