import { AnimatePresence, motion } from "framer-motion";
import ChevronDown from "assets/icons/mentorship-plan/template-month/expand-below-chevron-down.svg";
import { useState } from "react";
import TopicDetails from "./topic-detais/topic-details";
import { MentorshipPlanMentorTopic } from "types/mentorship-plan";
import {
  topicsForMonthCollapsed,
  topicsForMonthExpanded,
} from "../../../../analytics/index.analytics";

type TopicsForTheMonthProps = {
  groupTopicsBySubject?: Record<string, MentorshipPlanMentorTopic[]>;
  mentorPlanTopicsData?: MentorshipPlanMentorTopic[];
  openedCoveringSection: "" | "Topics" | "Resources";
  handleOpenCoveringSection: (section: "Topics" | "Resources" | "") => void;
  defaultAnalyticsPayload?: any;
};

const TopicsForTheMonth = ({
  groupTopicsBySubject,
  mentorPlanTopicsData,
  openedCoveringSection,
  handleOpenCoveringSection,
  defaultAnalyticsPayload,
}: TopicsForTheMonthProps) => {
  const [openedTopicIndex, setOpenedTopicIndex] = useState(-1);
  const handleOpenedTopicIndex = (index: number) => {
    setOpenedTopicIndex(index);
  };

  return (
    <>
      {groupTopicsBySubject ? (
        <div className="border border-[#333] rounded-lg py-3 px-[14px]">
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={() => {
              if (openedCoveringSection === "Topics") {
                handleOpenCoveringSection("");
                topicsForMonthCollapsed({
                  ...defaultAnalyticsPayload,
                  track_details: {
                    ...defaultAnalyticsPayload.track_details,
                    totalTopics: Object.keys(groupTopicsBySubject || {})
                      ?.length,
                    totalSubTopics: mentorPlanTopicsData?.length,
                  },
                });
              } else {
                handleOpenCoveringSection("Topics");
                topicsForMonthExpanded({
                  ...defaultAnalyticsPayload,
                  track_details: {
                    ...defaultAnalyticsPayload.track_details,
                    totalTopics: Object.keys(groupTopicsBySubject || {})
                      ?.length,
                    totalSubTopics: mentorPlanTopicsData?.length,
                  },
                });
              }
            }}
          >
            <div className="flex items-center gap-3">
              <img src={ChevronDown} alt="chevron-down.svg" />
              <p className="text-xs font-medium text-[#fff]">
                Topics For The Month
              </p>
            </div>
            <div className=" flex items-center gap-1.5  bg-[rgba(18,75,104,0.40)] rounded px-2 py-1">
              <p className="text-[11px] text-[#47C2FF]">
                {Object.keys(groupTopicsBySubject || {})?.length} Topics
              </p>
              <p className="text-[11px] text-[#47C2FF]">•</p>
              <p className="text-[11px] text-[#47C2FF]">
                {mentorPlanTopicsData?.length} Sub Topics
              </p>
            </div>
          </div>

          <AnimatePresence>
            {openedCoveringSection === "Topics" && (
              <motion.div
                className="overflow-hidden"
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <div className="my-4 h-[1px] w-full bg-[#222] md:my-5" />
                <div className="space-y-3 pl-9">
                  {Object.entries(groupTopicsBySubject || {})?.map(
                    ([subject, topics], subjectIndex) => {
                      return (
                        <TopicDetails
                          subject={subject}
                          topics={topics}
                          key={subjectIndex}
                          index={subjectIndex}
                          openedTopicIndex={openedTopicIndex}
                          handleOpenedTopicIndex={handleOpenedTopicIndex}
                          defaultAnalyticsPayload={{
                            ...defaultAnalyticsPayload,
                            track_details: {
                              ...defaultAnalyticsPayload.track_details,
                              topicName: subject,
                            },
                          }}
                        />
                      );
                    }
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TopicsForTheMonth;
