import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import config from "env.config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import "tw-elements";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./state/store";

Sentry.init({
  dsn: config.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: "planner@" + config.REACT_APP_PROJECT_VERSION,
  environment: config.REACT_APP_SERVER,
  // ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

declare global {
  interface Window {
    analytics: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
