import { MentorshipPlanMentorAssignments, MentorshipPlanMentorGoal, MentorshipPlanMentorProjects, MentorshipPlanMentorResources, MentorshipPlanMentorSession, MentorshipPlanMentorTopic, MentorshipPlanTemplate } from "types/mentorship-plan";
import { handleDashboardGet } from "./axios";
import { mentorshipPlanMentorAssignmentsTransformer, mentorshipPlanMentorGoalTransformer, mentorshipPlanMentorProjectsTransformer, mentorshipPlanMentorResourcesTransformer, mentorshipPlanMentorSessionTransformer, mentorshipPlanMentorTopicTransformer, mentorshipPlansTransformer } from "utils/mapper/mentorship-plan-mapper";


// For GEtiing Plans from MentorId
export const getMentorMentorshipPlans = async (
	requestParam: any
): Promise<MentorshipPlanTemplate[]> => {
	let response = await handleDashboardGet(
		"/mentorship-plans/mentor-plans",
		requestParam
	);

	response["data"] = transformMentorshipPlans(response["data"]);
	return response["data"];
};

const transformMentorshipPlans = (response: any) => {
	const mappedMentorshipPlans: MentorshipPlanTemplate[] = [];
	if (response && response.length > 0) {
		response.forEach((mentorshipPlan: any) => {
			mappedMentorshipPlans.push(mentorshipPlansTransformer(mentorshipPlan));
		});
	}
	return mappedMentorshipPlans;
};

//  For getting Topics
export const getMentorshipPlanMentorTopics = async (
	requestParam: any
): Promise<MentorshipPlanMentorTopic[]> => {
	let response = await handleDashboardGet(
		"/mentorship-plans/mentor-topics",
		requestParam
	);

	response["data"] = transformMentorshipPlanMentorTopics(response["data"]);
	return response["data"];
};

const transformMentorshipPlanMentorTopics = (response: any) => {
	const mappedMentorshipPlanMentorTopics: MentorshipPlanMentorTopic[] = [];
	if (response && response.length > 0) {
		response = response.sort((a: any, b: any) => a.order - b.order);
		response.forEach((mentorshipPlanMentorTopic: any) => {
			mappedMentorshipPlanMentorTopics.push(mentorshipPlanMentorTopicTransformer(mentorshipPlanMentorTopic));
		});
	}
	return mappedMentorshipPlanMentorTopics;
};

// For Getting Goals
export const getMentorshipPlanMentorGoals = async (
	requestParam: any
): Promise<MentorshipPlanMentorGoal[]> => {
	let response = await handleDashboardGet(
		"/mentorship-plans/mentor-goals",
		requestParam
	);

	response["data"] = transformMentorshipPlanMentorGoals(response["data"]);
	return response["data"];
};

const transformMentorshipPlanMentorGoals = (response: any) => {
	const mappedMentorshipPlanMentorGoals: MentorshipPlanMentorGoal[] = [];
	if (response && response.length > 0) {
		response.forEach((mentorshipPlanMentorGoal: any) => {
			mappedMentorshipPlanMentorGoals.push(mentorshipPlanMentorGoalTransformer(mentorshipPlanMentorGoal));
		});
	}
	return mappedMentorshipPlanMentorGoals;
};

// For getiing Sessions 
export const getMentorshipPlanMentorSessions = async (
	requestParam: any
): Promise<MentorshipPlanMentorSession[]> => {
	let response = await handleDashboardGet(
		"/mentorship-plans/mentor-sessions",
		requestParam
	);

	response["data"] = transformMentorshipPlanMentorSessions(response["data"]);
	return response["data"];
};


const transformMentorshipPlanMentorSessions = (response: any) => {
	let mappedMentorshipPlanMentorSessions: MentorshipPlanMentorSession[] = [];
	if (response && response.length > 0) {
		response.forEach((mentorSession: any) => {
			mappedMentorshipPlanMentorSessions.push(mentorshipPlanMentorSessionTransformer(mentorSession));
		});

		mappedMentorshipPlanMentorSessions = mappedMentorshipPlanMentorSessions.sort((a, b) => a.index - b.index);
	}
	return mappedMentorshipPlanMentorSessions;
};

// For Getting Resources
export const getMentorshipPlanMentorResources = async (
	requestParam: any
): Promise<MentorshipPlanMentorResources[]> => {
	let response = await handleDashboardGet(
		"/mentorship-plans/mentor-resources",
		requestParam
	);

	response["data"] = transformMentorshipPlanMentorResources(response["data"]);
	return response["data"];
};

const transformMentorshipPlanMentorResources = (response: any) => {
	const mappedMentorshipPlanMentorResources: MentorshipPlanMentorResources[] = [];
	if (response && response.length > 0) {
		response.forEach((mentorshipPlanMentorResource: any) => {
			mappedMentorshipPlanMentorResources.push(mentorshipPlanMentorResourcesTransformer(mentorshipPlanMentorResource));
		});
	}
	return mappedMentorshipPlanMentorResources;
};


// For Getting Assignments
export const getMentorshipPlanMentorAssignments = async (
	requestParam: any
): Promise<MentorshipPlanMentorAssignments[]> => {
	let response = await handleDashboardGet(
		"/mentorship-plans/mentor-assignments",
		requestParam
	);

	response["data"] = transformMentorshipPlanMentorAssignments(response["data"]);
	return response["data"];
};

const transformMentorshipPlanMentorAssignments = (response: any) => {
	const mappedMentorshipPlanMentorAssignments: MentorshipPlanMentorAssignments[] = [];
	if (response && response.length > 0) {
		response.forEach((mentorshipPlanMentorAssignment: any) => {
			mappedMentorshipPlanMentorAssignments.push(mentorshipPlanMentorAssignmentsTransformer(mentorshipPlanMentorAssignment));
		});
	}
	return mappedMentorshipPlanMentorAssignments;
};


// For Getting Projects
export const getMentorshipPlanMentorProjects = async (
	requestParam: any
): Promise<MentorshipPlanMentorProjects[]> => {
	let response = await handleDashboardGet(
		"/mentorship-plans/mentor-projects",
		requestParam
	);

	response["data"] = transformMentorshipPlanMentorProjects(response["data"]);
	return response["data"];
};

const transformMentorshipPlanMentorProjects = (response: any) => {
	const mappedMentorshipPlanMentorProjects: MentorshipPlanMentorProjects[] = [];
	if (response && response.length > 0) {
		response.forEach((mentorshipPlanMentorProject: any) => {
			mappedMentorshipPlanMentorProjects.push(mentorshipPlanMentorProjectsTransformer(mentorshipPlanMentorProject));
		});
	}
	return mappedMentorshipPlanMentorProjects;
};

