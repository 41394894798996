import { trackAnalytics } from "api/analytics";
import { sendAnalyticsToSegment } from "api/analyticsV2";
import { newEventPropertyBuilder } from "api/planning";

const expandAllMonthsClicked = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ ...properties, event_name: "MP - Expand All Months" }));
};

const collapsedAllMonthsClicked = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Collapsed All Months', ...properties }));
};

const mentorshipMonthExpanded = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Mentorship Month Expanded', ...properties }));
};

const mentorshipMonthCollapsed = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Mentorship Month Collapsed', ...properties }));
};

const topicsForMonthExpanded = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Topics For Month Expanded', ...properties }));
};

const topicsForMonthCollapsed = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Topics For Month Collapsed', ...properties }));
};

const topicDetailsExpanded = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Topic Details Expanded', ...properties }));
};

const topicDetailsCollapsed = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Topic Details Collapsed', ...properties }));
};

const showAllTopicsClicked = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Show All Topics', ...properties }));
};

const resourcesAssignmentsProjectsExpanded = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Resources/Assignments/Projects Expanded', ...properties }));
};

const resourcesAssignmentsProjectsCollapsed = (properties: any = {}) => {
	trackAnalytics(newEventPropertyBuilder({ event_name: 'MP - Resources/Assignments/Projects Collapsed', ...properties }));
};

export {
	collapsedAllMonthsClicked,
	expandAllMonthsClicked,
	mentorshipMonthCollapsed,
	mentorshipMonthExpanded,
	resourcesAssignmentsProjectsCollapsed,
	resourcesAssignmentsProjectsExpanded,
	showAllTopicsClicked,
	topicDetailsCollapsed,
	topicDetailsExpanded,
	topicsForMonthExpanded,
	topicsForMonthCollapsed,
};
