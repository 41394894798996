import React from "react";

type Props = {};

const MentorStepsSection = (props: Props) => {
  return (
    <div className="px-6">
      <p className="text-white font-semibold text-2xl mb-7">
        Checklist to Get You Started
      </p>
      <div className="flex flex-col relative">
        <div className="relative flex gap-x-4 pb-11 border-l border-dotted border-white pl-[34px]">
          <p className="absolute leading-none -left-[14px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            1
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Introduce Yourself{" "}
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Kick off by introducing yourself and making sure your mentee feels
              comfortable during the trial session, make sure mentee is okay
              with the language you&apos;re using.
            </p>
          </div>
        </div>
        <div className="relative flex gap-x-4 pb-11 border-l border-dotted border-white pl-[34px]">
          <p className="absolute leading-none -left-[14px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            2
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Address Mentee&apos;s Pain Point
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Work on identifying the main pain points of the mentee by
              utilizing the resume and the answers submitted by them.
            </p>
          </div>
        </div>
        <div className="relative flex gap-x-4 pb-11 border-l border-dotted border-white pl-[34px]">
          <p className="absolute leading-none -left-[14px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            3
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Discuss Mentorship Timeline
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Discuss a potential long-term mentorship period needed to achieve
              the mentee&apos;s goal and the necessary steps for it.
            </p>
          </div>
        </div>
        <div className="relative flex gap-x-4 pb-11 border-white pl-[34px]">
          <p className="absolute leading-none -left-[13px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            4
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Send Mentorship Plan
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Send a mentorship plan to the mentee if you find them suitable for
              your mentorship. Include a coupon and discuss pricing with the
              mentee to facilitate the purchase.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorStepsSection;
