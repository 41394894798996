import React from "react";
import UploadMenteeResume from "./components/UploadMenteeResume";

type Props = {};

const MenteeStepsSection = (props: Props) => {
  return (
    <div className="px-6">
      <p className="text-white font-semibold text-2xl mb-7">
        Checklist to Get You Started
      </p>
      <div className="flex flex-col relative">
        <div className="relative flex gap-x-4 pb-11 border-l border-dotted border-white pl-[34px]">
          <p className="absolute leading-none -left-[14px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            1
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Introduce Yourself
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Introducing yourself to the mentor in a professional manner,
              discuss your pain points and the problems you're facing in
              achieving your goals.
            </p>
            {/* <UploadMenteeResume /> */}
          </div>
        </div>
        <div className="relative flex gap-x-4 pb-11 border-l border-dotted border-white pl-[34px]">
          <p className="absolute leading-none -left-[14px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            2
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Ask How Mentor can help you
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Discuss about the roadmap and about how your mentor will help you
              in the Long-Term Mentorship program.
            </p>
          </div>
        </div>
        <div className="relative flex gap-x-4 pb-11 border-l border-dotted border-white pl-[34px]">
          <p className="absolute leading-none -left-[14px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            3
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Discuss Mentorship Timeline
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Discuss a potential long-term mentorship period needed to achieve
              the your goal and the necessary steps for it.
            </p>
          </div>
        </div>
        <div className="relative flex gap-x-4 pb-11 border-white pl-[34px]">
          <p className="absolute leading-none -left-[14px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black">
            4
          </p>
          <div className="flex flex-col gap-y-1.5">
            <p className="text-base font-semibold text-white">
              Discuss about Potential Discounts
            </p>
            <p className="text-[13px] text-white/[0.7] ">
              Ask your mentor if they are willing to offer any discount coupons
              for the 3- and 6-month Long-Term Mentorship Program
            </p>
          </div>
        </div>
        {/* <div className='relative flex gap-x-4 pb-11 border-white pl-[34px]'>
          <p className='absolute leading-none -left-[13px] flex justify-center items-center font-semibold w-7 h-7 text-center bg-white rounded-full text-black'>5</p>
          <div className='flex flex-col gap-y-1.5'>
            <p className='text-base font-semibold text-white'>Discuss about Potential Discounts</p>
            <p className='text-[13px] text-white/[0.7] '>Ask your mentor if they are willing to offer any discount coupons for the 3- and 6-month Long-Term Mentorship Program</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MenteeStepsSection;
