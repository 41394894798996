import { getMentorshipPlanMentorGoals } from "api/mentorship-curriculm";
import { useEffect, useState } from "react";
import { MentorshipPlanMentorGoal, TemplateMonth as TemplateMonthType } from "types/mentorship-plan";


type UseGoalProps = {
  templateMonthData: TemplateMonthType;
};

const useGoal = ({ templateMonthData }: UseGoalProps) => {
  const [mentorPlanGoalsData, setMentorPlanGoalsData] = useState<MentorshipPlanMentorGoal[]>([])

  useEffect(() => {
    getMentorshipPlanMentorGoals({ goal_ids: templateMonthData?.mentorPlanGoals?.join(',') }).then((data) => {
      setMentorPlanGoalsData(data)
    })
  }, [])

  const mentorPlanGoal = mentorPlanGoalsData && mentorPlanGoalsData?.length > 0 ? mentorPlanGoalsData[0] : undefined;

  return {
    mentorPlanGoal,
  };
};

export default useGoal;
