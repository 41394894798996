import { AnimatePresence, motion } from "framer-motion";
import GoalsAndSessions from "./goal-and-sessions/goal-and-sessions";
import TopicsToBeCovered from "./topics-to-be-covered/topics-to-be-covered";
import useTemplateMonth from "./use-template-month";
import { TemplateMonth as TemplateMonthType } from "types/mentorship-plan";
import PlusIcon from "assets/icons/mentorship-plan/plus-icon-white.svg";
import MinusIcon from "assets/icons/mentorship-plan/minus-icon-white.svg";

type TemplateMonthProps = {
  templateMonthData: TemplateMonthType;
  index: number;
  expandedMonthIndex?: number;
  expandedMonthsArray: number[];
  handleExpandedMonthsArray: (index: number) => void;
  openTopicsSection?: boolean;
  defaultAnalyticsPayload?: any;
};

const TemplateMonth = ({
  templateMonthData,
  index,
  handleExpandedMonthsArray,
  expandedMonthsArray,
  openTopicsSection,
  defaultAnalyticsPayload,
}: TemplateMonthProps) => {
  const {
    totalSessionsWithMentor,
    totalTopicsIncluded,
    totalStudyMaterial,
    mentorPlanGoal,
    mentorPlanSessionsData,
    assetsData,
    mentorPlanTopicsData,
    groupTopicsBySubject,
  } = useTemplateMonth({
    templateMonthData,
  });

  const isExpanded = expandedMonthsArray?.includes(index);

  return (
    <div className="bg-[#161616] rounded-xl p-4">
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={() => {
          handleExpandedMonthsArray(index);
        }}
      >
        <div className="flex items-center gap-4">
          <div className="space-y-[5px]">
            <p className="font-semibold text-[#FFF] text-[15px] leading-[22px]">
              Month {templateMonthData?.month} of Mentorship
            </p>
            <div className="flex items-center gap-3">
              <p className="whitespace-nowrap text-xs text-[#717784] md:hidden">
                Module Contains:
              </p>
              <p className="hidden text-[13px] text-[#717784] md:block">
                This Module Contains Following:
              </p>
              <div className="hidden gap-2 md:flex">
                <div className="flex justify-center items-center h-5 p-2 rounded bg-[rgba(11,70,39,0.30)]  text-[11px] leading-3 font-medium text-[#1FC16B]">
                  {totalSessionsWithMentor} Sessions
                </div>
                <div className="flex justify-center items-center h-5 p-2 rounded bg-[rgba(18,75,104,0.40)] text-[11px] leading-3 font-medium text-[#47C2FF]">
                  {totalTopicsIncluded} Topics
                </div>
                {totalStudyMaterial > 0 ? (
                  <div className="flex justify-center items-center h-5 p-2 rounded bg-[rgba(104,47,18,0.40)] text-[11px] leading-3 font-medium text-[#FF8447]">
                    {totalStudyMaterial} Study Material
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <button>
          <img
            src={isExpanded ? MinusIcon : PlusIcon}
            alt="expand-collapse.svg"
          />
        </button>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="overflow-hidden"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <div className="my-6 h-[1px] w-full bg-[#222]" />
            <GoalsAndSessions
              mentorPlanGoal={mentorPlanGoal}
              mentorPlanSessionsData={mentorPlanSessionsData}
            />
            <div className="my-6 h-[1px] w-full bg-[#222]" />
            <TopicsToBeCovered
              assetsData={assetsData}
              mentorPlanTopicsData={mentorPlanTopicsData}
              groupTopicsBySubject={groupTopicsBySubject}
              openTopicsSection={openTopicsSection}
              defaultAnalyticsPayload={{
                ...defaultAnalyticsPayload,
                track_details: {
                  ...defaultAnalyticsPayload.track_details,
                  monthVal: index + 1,
                },
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TemplateMonth;
