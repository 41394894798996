import React, { useState } from "react";
import MentorTechnicalSkills from "./components/MentorTechnicalSkills";
import { ReadMore } from "common/ReadMore/ReadMore";
import MentorLanguagesList from "./components/MentorLanguagesList";
import CheckIconCustomStroke from "assets/icons/check-icon-custom-stroke.svg";

type Props = {
  mentorData: any;
};

const MentorDetailsSection = ({ mentorData }: Props) => {
  const mentorshipPreferencesData = [
    {
      id: 1,
      label: `${
        mentorData &&
        mentorData?.sessionFrequency &&
        mentorData?.sessionFrequency
      }x Sessions/Week`,
      exist:
        mentorData &&
        mentorData?.sessionFrequency &&
        mentorData?.sessionFrequency
          ? true
          : false,
    },
    {
      id: 2,
      label: "Regular Task & Resources ",
      exist: true,
    },
    {
      id: 3,
      label: "Unlimited Preplaced Chat",
      exist: true,
    },
    {
      id: 4,
      label: "Hands on Projects",
      exist: true,
    },
    {
      id: 5,
      label:
        mentorData &&
        mentorData.adhocCalls &&
        mentorData.adhocCalls.charAt(0).toUpperCase() +
          mentorData.adhocCalls.slice(1).toLowerCase() ===
          "Yes"
          ? "Instant Call Support"
          : "",
      exist:
        mentorData &&
        mentorData.adhocCalls &&
        mentorData.adhocCalls.charAt(0).toUpperCase() +
          mentorData.adhocCalls.slice(1).toLowerCase() ===
          "Yes"
          ? true
          : false,
    },
    {
      id: 6,
      label: "Resume review",
      exist: true,
    },
    {
      id: 7,
      label: "Mock Interviews",
      exist: true,
    },
    {
      id: 8,
      label: "Job Referrals for Your Dream Job",
      exist:
        mentorData &&
        mentorData["referralPreference"] &&
        mentorData["referralPreference"].length > 0
          ? true
          : false,
    },
  ];

  return (
    <div className="flex flex-col gap-y-7">
      {/* Mentor About */}
      {mentorData?.longTermPitch && (
        <div>
          <p className="mb-3 text-[13px] font-semibold text-white">About:</p>
          <ReadMore
            minTextLength={200}
            className="text-[13px] text-[rgba(255,255,255,0.50)] leading-5"
          >
            {mentorData?.longTermPitch}
          </ReadMore>
        </div>
      )}

      {/* Mentor Skills */}
      {mentorData?.technicalSkillsJson && (
        <div>
          <p className="mb-3 text-[13px] font-semibold text-white">Skills:</p>
          <MentorTechnicalSkills
            technicalSkillsJson={mentorData?.technicalSkillsJson}
            minCount={3}
          />
        </div>
      )}

      {/* Mentor Languages */}
      {mentorData?.languagePreference && (
        <div>
          <p className="mb-3 text-[13px] font-semibold text-white">
            Languages I Speak:
          </p>
          <MentorLanguagesList
            mentorLanguagesList={mentorData?.languagePreference}
            minCount={3}
          />
        </div>
      )}

      {/* Mentorship Preferences */}
      <div>
        <p className="mb-3 text-xs font-bold text-white">
          Mentorship Preferences:
        </p>
        <div className="grid grid-cols-2 gap-x-5 gap-y-3">
          {mentorshipPreferencesData.map((data) => {
            if (data.exist) {
              return (
                <div className="w-full h-fit flex items-center gap-x-1.5 text-xs text-white">
                  <img src={CheckIconCustomStroke} alt="GreenCheck.svg" />
                  {data.label}
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default MentorDetailsSection;
