import GoalIcon from "assets/icons/mentorship-plan/template-month/goal.svg";
import PlanningSessionIcon from "assets/icons/mentorship-plan/template-month/planning-session.svg";
import UpskillingSessionIcon from "assets/icons/mentorship-plan/template-month/upskilling-session.svg";
import DoubtSessionIcon from "assets/icons/mentorship-plan/template-month/doubt-session.svg";
import RetrospectiveSessionIcon from "assets/icons/mentorship-plan/template-month/retrospective-session.svg";
import ResumeBuildingSessionIcon from "assets/icons/mentorship-plan/template-month/resume-building-session.svg";
import MockInterviewSessionIcon from "assets/icons/mentorship-plan/template-month/mock-interview-session.svg";
import JobApplicationSessionIcon from "assets/icons/mentorship-plan/template-month/job-application-session.svg";
import LinkedinOptimisationSessionIcon from "assets/icons/mentorship-plan/template-month/linkedin-optimisation-session.svg";
import EvaluationSessionIcon from "assets/icons/mentorship-plan/template-month/evaluation-session.svg";
import SalaryNegotiationSessionIcon from "assets/icons/mentorship-plan/template-month/salary-negotiation-session.svg";
import InstantCallIcon from "assets/icons/mentorship-plan/template-month/instant-call.svg";
import InstantMessageIcon from "assets/icons/mentorship-plan/template-month/instant-chat.svg";

import {
  MentorshipPlanMentorGoal,
  MentorshipPlanMentorSession,
} from "types/mentorship-plan";

type GoalsAndSessionsProps = {
  mentorPlanGoal?: MentorshipPlanMentorGoal;
  mentorPlanSessionsData?: MentorshipPlanMentorSession[];
};

const GoalsAndSessions = ({
  mentorPlanGoal,
  mentorPlanSessionsData = [],
}: GoalsAndSessionsProps) => {
  const sessionMap = new Map();
  mentorPlanSessionsData?.forEach((session) => {
    const { type } = session;
    if (!sessionMap.has(type)) {
      sessionMap.set(type, []);
    }
    sessionMap.get(type).push(session);
  });
  const groupedSessions = Object.fromEntries(sessionMap);

  return (
    <div className="space-y-4">
      <p className="hidden text-[13px] font-medium text-[#fff] ">
        Goal & 1:1 Sessions For The Month
      </p>
      <div className="flex items-center gap-2 border border-[#333] py-2.5 px-2 rounded-lg">
        <div className="flex items-center gap-1 shrink-0">
          <img src={GoalIcon} alt="goal.svg" />
          <p className="text-xs font-medium text-[#fff]">Goal:</p>
        </div>
        <p className="text-xs font-medium text-[#fff]">
          {mentorPlanGoal?.goal}
        </p>
      </div>

      <div className="">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-[14px]">
          {groupedSessions["Planning Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img src={PlanningSessionIcon} alt="planning.svg" />
              <p className="text-xs font-medium text-[#FFF] ">
                <span className="font-bold">
                  {groupedSessions["Planning Session"]?.length}{" "}
                </span>
                x Planning Session
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Upskilling Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img src={UpskillingSessionIcon} alt="upskilling.svg" />
              <p className="text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Upskilling Session"]?.length}
                </span>{" "}
                x Upskilling Sessions
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Doubt Solving Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img src={DoubtSessionIcon} alt="doubt-session.svg" />
              <p className="text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Doubt Solving Session"]?.length}
                </span>{" "}
                x Doubt Clearing Session
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Monthly Retrospective Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img
                src={RetrospectiveSessionIcon}
                alt="retrospective-session.svg"
              />
              <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Monthly Retrospective Session"]?.length}
                </span>{" "}
                x Monthly Retrospective Session
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Resume Building Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img
                src={ResumeBuildingSessionIcon}
                alt="resume-building-session.svg"
              />
              <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Resume Building Session"]?.length}
                </span>{" "}
                x Resume Building Session
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Mock Interview Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img
                src={MockInterviewSessionIcon}
                alt="mock-interview-session.svg"
              />
              <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Mock Interview Session"]?.length}
                </span>{" "}
                x Mock Interview Session
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Job Application Sesssion"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img
                src={JobApplicationSessionIcon}
                alt="job-application-session.svg"
              />
              <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Job Application Sesssion"]?.length}
                </span>{" "}
                x Job Application Sesssion
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Salary Negotiation Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img
                src={SalaryNegotiationSessionIcon}
                alt="salary-negotiation-session.svg"
              />
              <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Salary Negotiation Session"]?.length}
                </span>{" "}
                x Salary Negotiation Session
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["LinkedIn Optimisation Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img
                src={LinkedinOptimisationSessionIcon}
                alt="linkedin-optimisation.svg"
              />
              <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["LinkedIn Optimisation Session"]?.length}
                </span>{" "}
                x LinkedIn Optimisation Session
              </p>
            </div>
          ) : (
            <></>
          )}

          {groupedSessions["Evaluation Session"]?.length > 0 ? (
            <div className="flex w-full items-center gap-2 border-l-[3px] border-[#009E3D] bg-[rgba(11,70,39,0.20)] py-2 pl-3 px-2.5">
              <img src={EvaluationSessionIcon} alt="evaluation-session.svg" />
              <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
                <span className="font-bold">
                  {groupedSessions["Evaluation Session"]?.length}
                </span>{" "}
                x Evaluation Session
              </p>
            </div>
          ) : (
            <></>
          )}

          <div className="flex w-full items-center gap-2 border-l-[3px] border-[#FB4BA3] bg-[rgba(104,18,61,0.20)] py-2 pl-3 px-2.5">
            <img src={InstantCallIcon} alt="instant-call.svg" />
            <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
              Instant Call With Mentor
            </p>
          </div>
          <div className="flex w-full items-center gap-2 border-l-[3px] border-[#FB4BA3] bg-[rgba(104,18,61,0.20)] py-2 pl-3 px-2.5">
            <img src={InstantMessageIcon} alt="instant-chat.svg" />
            <p className="whitespace-nowrap text-xs font-medium text-[#FFF]">
              Instant Chat With Mentor
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsAndSessions;
