import React from "react";
import MenteeTargetDomains from "./components/MenteeTargetDomains";
import MenteeTargetCompanies from "./components/MenteeTargetCompanies";
import { ReadMore } from "common/ReadMore/ReadMore";

type Props = {
  menteeData: any;
};

const MenteeDetailsSection = ({ menteeData }: Props) => {
  return (
    <div className="flex flex-col gap-y-7">
      {/* Mentee Persona */}
      <div>
        <p className="mb-3 text-[13px] leading-[18px] font-semibold text-white">
          Mentee Persona:
        </p>
        <div>
          <span className="px-3 py-2 text-[11px] leading-[18px] text-white bg-[#222] rounded-full">
            {menteeData?.userCategory === "Fresher"
              ? "🌝 Fresher"
              : "🚀 Working Professional"}
          </span>
        </div>
      </div>

      {/* Mentee Target Domains */}
      <div>
        <p className="mb-3 text-[13px] leading-[18px] font-semibold text-white">
          Target Domains:
        </p>
        <MenteeTargetDomains
          targetDomains={menteeData?.targetDomains}
          minCount={2}
        />
      </div>

      {/* Mentee Target Companies */}
      <div>
        <p className="mb-3 text-[13px] leading-[18px] font-semibold text-white">
          Target Companies:
        </p>
        <MenteeTargetCompanies
          targetCompanies={menteeData?.targetCompanies}
          minCount={2}
        />
      </div>

      {/* Questions */}
      {menteeData?.longTermMentorshipHelp && (
        <div>
          <p className="mb-3 text-[13px] leading-[18px] font-semibold text-white">
            Questions:
          </p>
          <p className="text-xs pb-1.5 text-white">
            What problems are you facing in achieving your goals?
          </p>
          <ReadMore
            minTextLength={150}
            className="text-[13px] text-[rgba(255,255,255,0.50)] leading-5"
          >
            {menteeData?.longTermMentorshipHelp}
          </ReadMore>
        </div>
      )}
    </div>
  );
};

export default MenteeDetailsSection;
