import { getMentorshipPlanMentorSessions } from "api/mentorship-curriculm";
import { useEffect, useState } from "react";
import { MentorshipPlanMentorSession, TemplateMonth as TemplateMonthType } from "types/mentorship-plan";

type UseSessionsProps = {
  templateMonthData: TemplateMonthType;
};

const useSessions = ({ templateMonthData }: UseSessionsProps) => {
  const [mentorPlanSessionsData, setMentorPlanSessionsData] = useState<MentorshipPlanMentorSession[]>([])

  useEffect(() => {
    getMentorshipPlanMentorSessions({ session_ids: templateMonthData?.mentorPlanSessions?.join(',') }).then((data) => {
      setMentorPlanSessionsData(data)
    })
  }, [])

  return {
    mentorPlanSessionsData,
  };
};

export default useSessions;
