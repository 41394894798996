import React, { lazy, Suspense, useEffect } from "react";
import logo from "./logo.svg";
import "./app.css";
import { Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import TopBarProgress from "react-topbar-progress-indicator";
// import NewSteps from './routes/trial-session/NewSteps';
// import NewSteps from "./routes/trial-v3/NewSteps";
import MenteeProfile from "./routes/MenteeProfile/MenteeProfile";
import TrialUpgrade from "./routes/trial-upgrade/trialUpgrade";
import PlanningSession from "routes/PlanningSession/PlanningSession";
import { useQuery } from "utils/hooks/useQuery";
import TrialUpgradeV2 from "routes/trial-upgrade-v2/TrialUpgradeV2";
import AddTasks from "routes/post-syncup-feedback-mentor/task-section/post-syncup-feedback-mentor";
import PostSyncUpFeedbackContainer from "routes/post-syncup-feedback-mentor";
import NewSteps from "routes/trial-v4/NewSteps";

/* --------------------------- progress bar config -------------------------- */
TopBarProgress.config({
  barColors: {
    "0": "#0054f2",
    "0.5": "#ff5554",
    "1.0": "#000",
  },
  barThickness: 2,
  shadowBlur: 5,
});
/* -------------------------------------------------------------------------- */

function App() {
  const params = useQuery();
  localStorage.setItem("mentorValidityToken", "true");
  localStorage.setItem("mentorCreatedTrialSession", "true");
  useEffect(() => {
    if (params["planner-doc-url"]) {
      document.body.style.backgroundColor = "black";
    }
  }, []);

  return (
    <div className="relative h-full">
      <Suspense fallback={<TopBarProgress />}>
        <Routes>
          {/* <Route path='/old-trial/:id' element={<Steps/>} /> */}
          <Route path="/trial/:id" element={<NewSteps />} />
          {/* <Route path='/mentee-profile/:id' element={<MenteeProfile/>}/> */}
          {/* <Route path='/old-upgrade/:id' element={<TrialUpgrade/>}/> */}
          <Route path="/upgrade/:id" element={<TrialUpgradeV2 />} />
          <Route path="/plan/:id" element={<PlanningSession />} />
          {/* Add Tasks at the end of each session after meeting end instead of feedback tally */}
          <Route
            path="post-syncup-feedback-mentor/:id"
            element={<PostSyncUpFeedbackContainer />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
