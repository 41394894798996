import React from "react";
import RightSide from "./right-side/RightSide";
import LeftSide from "./left-side/LeftSide";

const CommonView = () => {
  return (
    <div className="flex gap-4 px-4">
      <div className="flex-[0.4] max-h-[100vh]">
        <LeftSide />
      </div>
      <div className="flex-[0.6] max-h-[100vh]">
        <RightSide />
      </div>
    </div>
  );
};

export default CommonView;
