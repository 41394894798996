import { useEffect, useState } from "react";

import useGoal from "./hooks/use-goal";
import useSessions from "./hooks/use-sessions";
import useAssets from "./hooks/use-assets";
import useTopics from "./hooks/use-topics";
import { TemplateMonth as TemplateMonthType } from "types/mentorship-plan";

type UseTemplateMonthProps = {
  templateMonthData: TemplateMonthType;
};

const useTemplateMonth = ({ templateMonthData }: UseTemplateMonthProps) => {
  const { mentorPlanGoal } = useGoal({ templateMonthData });
  const { mentorPlanSessionsData } = useSessions({ templateMonthData });
  const assetsData = useAssets({ templateMonthData });
  const { mentorPlanTopicsData, groupTopicsBySubject } = useTopics({
    templateMonthData,
  });

  const totalSessionsWithMentor =
    templateMonthData?.mentorPlanSessions?.length || 0;
  const totalTopicsIncluded =
    Object.keys(groupTopicsBySubject || {})?.length || 0;
  const totalStudyMaterial =
    (templateMonthData?.mentorPlanAssignments?.length || 0) +
    (templateMonthData?.mentorPlanProjects?.length || 0) +
    (templateMonthData?.mentorPlanResources?.length || 0);

  return {
    totalSessionsWithMentor,
    totalTopicsIncluded,
    totalStudyMaterial,
    mentorPlanGoal,
    mentorPlanSessionsData,
    assetsData,
    mentorPlanTopicsData,
    groupTopicsBySubject,
  };
};

export default useTemplateMonth;
