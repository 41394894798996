import {

	MentorshipPlanMentorAssignments,
	MentorshipPlanMentorGoal,
	MentorshipPlanMentorProjects,
	MentorshipPlanMentorResources,
	MentorshipPlanMentorSession,
	MentorshipPlanMentorTopic,
	MentorshipPlanSession,
	MentorshipPlanTemplate,
	MentorshipPlanTopic,
} from "../../types/mentorship-plan"

const mentorshipPlansTransformer = (data: any) => {
	const mappedMentorshipPlans: MentorshipPlanTemplate = {
		recordId: data.recordId || '',
		title: data.title || '',
		description: data.description || '',
		templateForMonth: data.templateForMonth || 0,
		progress: data.progress || 0,
		default: data.default || false,
		months: data.months.map((month: any) => ({
			month: month.month || 0,
			mentorPlanGoals: month.mentorPlanGoals || [],
			mentorPlanTopics: month.mentorPlanTopics || [],
			mentorPlanSessions: month.mentorPlanSessions || [],
			mentorPlanResources: month.mentorPlanResources || [],
			mentorPlanAssignments: month.mentorPlanAssignments || [],
			mentorPlanProjects: month.mentorPlanProjects || [],
			progress: month.progress || 0,
		})),
	};
	return mappedMentorshipPlans;
};

const mentorshipPlanMentorGoalTransformer = (data: any) => {
	const mappedMentorshipPlanMentorGoal: MentorshipPlanMentorGoal = {
		recordId: data.recordId || '',
		category: data.category || '',
		goal: data.goal || '',
	};
	return mappedMentorshipPlanMentorGoal;
};

const mentorshipPlanMentorTopicTransformer = (data: any) => {
	const mappedMentorshipPlanMentorTopic: MentorshipPlanMentorTopic = {
		recordId: data.recordId || '',
		topic: data.topic || '',
		subject: data.subject || '',
		domains: data.domains || [],
		planTopics: data.planTopics || [],
		mentorPlanResources: data.mentorPlanResources || [],
		mentorPlanAssignments: data.mentorPlanAssignments || [],
		mentorPlanProjects: data.mentorPlanProjects || [],
	};
	return mappedMentorshipPlanMentorTopic;
};

const mentorshipPlanMentorSessionTransformer = (data: any) => {
	const mappedMentorSession: MentorshipPlanMentorSession = {
		index: data.index || 0,
		recordId: data.recordId || '',
		type: data.type || '',
		month: data.month || 0,
		duration: data.duration || 0,
		about: data.about || '',
		mentors: data.mentors || [],
		planSessions: data.planSessions || [],
		logo: data.logo || '',
		details: data.details || '',
	};
	return mappedMentorSession;
};

const mentorshipPlanMentorResourcesTransformer = (data: any) => {
	const mappedMentorshipPlanMentorResources: MentorshipPlanMentorResources = {
		recordId: data.recordId || '',
		mentorPlanTopics: data.mentorPlanTopics || [],
		title: data.title || '',
		type: data.type || '',
		url: data.url || '',
	};
	return mappedMentorshipPlanMentorResources;
};

const mentorshipPlanMentorAssignmentsTransformer = (data: any) => {
	const mappedMentorshipPlanMentorAssignments: MentorshipPlanMentorAssignments = {
		recordId: data.recordId || '',
		mentorPlanTopics: data.mentorPlanTopics || [],
		title: data.title || '',
		description: data.description || '',
		url: data.url || '',
	};
	return mappedMentorshipPlanMentorAssignments;
};

const mentorshipPlanMentorProjectsTransformer = (data: any) => {
	const mappedMentorshipPlanMentorProjects: MentorshipPlanMentorProjects = {
		recordId: data.recordId || '',
		mentorPlanTopics: data.mentorPlanTopics || [],
		title: data.title || '',
		description: data.description || '',
		url: data.url || '',
	};
	return mappedMentorshipPlanMentorProjects;
};

const mentorshipPlanSessionTransformer = (data: any) => {
	const mappedMentorshipPlanSession: MentorshipPlanSession = {
		recordId: data.recordId || '',
		type: data.type || '',
		details: data.details || '',
		logo: data.logo || '',
	};
	return mappedMentorshipPlanSession;
};

const mentorshipPlanTopicTransformer = (data: any) => {
	const mappedMentorshipPlanTopic: MentorshipPlanTopic = {
		recordId: data.recordId || '',
		domains: data.domains || [],
		subject: data.subject || '',
		title: data.title || '',
		mentorPlanTopic: data.mentorPlanTopic || '',
	};
	return mappedMentorshipPlanTopic;
};

export {
	mentorshipPlanMentorAssignmentsTransformer,
	mentorshipPlanMentorGoalTransformer,
	mentorshipPlanMentorProjectsTransformer,
	mentorshipPlanMentorResourcesTransformer,
	mentorshipPlanMentorSessionTransformer,
	mentorshipPlanMentorTopicTransformer,
	mentorshipPlanSessionTransformer,
	mentorshipPlansTransformer,
	mentorshipPlanTopicTransformer,
};
