import { useState } from "react";
import { useAppSelector } from "state/hooks";

const useMentorsipCurriculum = () => {
  const mentorMentorshipCurriculumData = useAppSelector(
    (state) => state.planner.mentorMentorshipCurriculumData
  );

  const [templateForMonths, setTemplateForMonths] = useState(6);
  const handleTemplateForMonths = (duration: number) => {
    setTemplateForMonths(duration);
  };

  const oneMonthDefaultTemplate =
    mentorMentorshipCurriculumData?.OneMonth?.mentorshipPlans?.find(
      (plan) => plan?.default
    );
  const threeMonthsDefaultTemplate =
    mentorMentorshipCurriculumData?.ThreeMonths?.mentorshipPlans?.find(
      (plan) => plan?.default
    );
  const sixMonthsDefaultTemplate =
    mentorMentorshipCurriculumData?.SixMonths?.mentorshipPlans?.find(
      (plan) => plan?.default
    );

  const templateDataToShow =
    templateForMonths === 1
      ? oneMonthDefaultTemplate
      : templateForMonths === 3
      ? threeMonthsDefaultTemplate
      : templateForMonths === 6
      ? sixMonthsDefaultTemplate
      : undefined;

  return {
    oneMonthDefaultTemplate,
    threeMonthsDefaultTemplate,
    sixMonthsDefaultTemplate,
    templateForMonths,
    handleTemplateForMonths,
    templateDataToShow,
  };
};

export default useMentorsipCurriculum;
