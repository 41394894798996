import { getMentorshipPlanMentorTopics } from "api/mentorship-curriculm";
import { useEffect, useState } from "react";
import { MentorshipPlanMentorTopic, TemplateMonth } from "types/mentorship-plan";


type UseTopicsProps = {
  templateMonthData: TemplateMonth;
};

const useTopics = ({ templateMonthData }: UseTopicsProps) => {
  const [mentorPlanTopicsData, setMentorPlanTopicsData] = useState<MentorshipPlanMentorTopic[]>([])

  useEffect(() => {
    getMentorshipPlanMentorTopics({ topic_ids: templateMonthData?.mentorPlanTopics?.join(',') }).then((data) => {
      setMentorPlanTopicsData(data)
    })
  }, [])

  const groupTopicsBySubject = mentorPlanTopicsData?.reduce((acc: Record<string, MentorshipPlanMentorTopic[]>, topic: MentorshipPlanMentorTopic) => {
    acc[topic.subject] = acc[topic.subject] || [];
    acc[topic.subject].push(topic);
    return acc;
  }, {});

  return {
    mentorPlanTopicsData,
    groupTopicsBySubject,
  };
};

export default useTopics;
