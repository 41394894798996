import { createSlice, PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { MentorshipCurriculumDataType, MentorshipPlanTemplate } from 'types/mentorship-plan'


let initialState = {
    // New Flow requirements
    menteeData: '' as any,
    mentorData: {} as any,
    mentorServices: [] as any,
    sessionId: "",

    //Old flow: To be removed
    painPoints: '',
    //Step 2
    interviewReadinessScore: 0,
    interviewReadinessMentee: '',   //TODO: to be removed
    interviewReadinessMentor: '',   //TODO: to be removed
    areasOfImprovementDomain: '',
    areasOfImprovementSkills: [] as any,
    effortsTakenByMentee: '',
    // Step 3
    mentorHelpAreasGuide: [] as any,
    mentorHelpAreasPractice: [] as any,
    mentorHelpAreasJobSearch: [] as any,
    timeDedicationWeekends: '',
    timeDedicationWeekdays: '',
    timeToInterviewReady: '',
    monthWiseGoals: '',
    mentorInfo: '' as any,
    pricingInfo: '' as any,
    trialStartedTime: null,
    trialCompletedTime: null,
    mentorMentorshipCurriculumData: {} as MentorshipCurriculumDataType
}

const plannerSlice = createSlice({
    name: 'planner',

    initialState: {
        ...initialState,
    },

    reducers: {
        setMenteeData: (state: any, action: PayloadAction<any>) => {
            state.menteeData = action.payload
        },
        setMentorData: (state: any, action: PayloadAction<any>) => {
            state.mentorData = action.payload
        },
        setMentorServices: (state: any, action: PayloadAction<any>) => {
            state.mentorServices = action.payload
        },

        setNewTrialDocQuestionsAnswer: (state: any, action: PayloadAction<any>) => {
            var trialDocQuestionsAnswer = action.payload.key
            state[trialDocQuestionsAnswer] = action.payload.value
        },
        setMentorInfo: (state: any, action: PayloadAction<any>) => {
            state.mentorInfo = action.payload
        },
        setPricingInfo: (state: any, action: PayloadAction<any>) => {
            state.pricingInfo = action.payload
        },
        setSessionId: (state: any, action: PayloadAction<string>) => {
            state.sessionId = action.payload
        },

        setMentorMentorshipCurriculumData: (state: any, action: PayloadAction<MentorshipCurriculumDataType>) => {
            state.mentorMentorshipCurriculumData = action.payload
        }

    },
})

export const {
    setMenteeData,
    setMentorData,
    setMentorServices,
    setNewTrialDocQuestionsAnswer,
    setMentorInfo,
    setPricingInfo,
    setSessionId,
    setMentorMentorshipCurriculumData
} = plannerSlice.actions

export default plannerSlice.reducer
export const selectPlanner = (state: any) => state.planner
