import React, { useEffect, useState } from "react";

import TopicsForTheMonth from "./topics-for-the-month/topics-for-the-month";
import ResourceAssignmentProjects from "./resource-assignment-projects/resource-assignment-projects";
import {
  MentorshipPlanMentorAssignments,
  MentorshipPlanMentorProjects,
  MentorshipPlanMentorResources,
  MentorshipPlanMentorTopic,
} from "types/mentorship-plan";

type TopicsToBeCoveredProps = {
  assetsData: {
    mentorshipPlanResourcesData?: MentorshipPlanMentorResources[];
    mentorshipPlanAssignmentsData?: MentorshipPlanMentorAssignments[];
    mentorshipPlanProjectsData?: MentorshipPlanMentorProjects[];
  };
  mentorPlanTopicsData?: MentorshipPlanMentorTopic[];
  groupTopicsBySubject?:
    | Record<string, MentorshipPlanMentorTopic[]>
    | undefined;
  openTopicsSection?: boolean;
  defaultAnalyticsPayload?: any;
};

const TopicsToBeCovered = ({
  assetsData,
  groupTopicsBySubject,
  mentorPlanTopicsData,
  openTopicsSection,
  defaultAnalyticsPayload,
}: TopicsToBeCoveredProps) => {
  const [openedCoveringSection, setOpendCoveringSection] = useState<
    "Topics" | "Resources" | ""
  >("");
  const handleOpenCoveringSection = (section: "Topics" | "Resources" | "") => {
    setOpendCoveringSection(section);
  };

  useEffect(() => {
    if (openTopicsSection) setOpendCoveringSection("Topics");
  }, [openTopicsSection]);

  return (
    <div className="space-y-4">
      <p className="text-[13px] font-medium text-[#fff]">
        What All Will Be Covered
      </p>
      <div className="space-y-3">
        <TopicsForTheMonth
          groupTopicsBySubject={groupTopicsBySubject}
          mentorPlanTopicsData={mentorPlanTopicsData}
          openedCoveringSection={openedCoveringSection}
          handleOpenCoveringSection={handleOpenCoveringSection}
          defaultAnalyticsPayload={defaultAnalyticsPayload}
        />
        <ResourceAssignmentProjects
          assetsData={assetsData}
          openedCoveringSection={openedCoveringSection}
          handleOpenCoveringSection={handleOpenCoveringSection}
          defaultAnalyticsPayload={defaultAnalyticsPayload}
        />
      </div>
    </div>
  );
};

export default TopicsToBeCovered;
