import ChevronDownIcon from "assets/icons/mentorship-plan/template-month/expand-below-chevron-down.svg";
import ChevronDown from "assets/icons/mentorship-plan/chevron-down-white.svg";
import TopicIcon from "assets/icons/mentorship-plan/template-month/topic-icon.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { MentorshipPlanMentorTopic } from "types/mentorship-plan";
import {
  showAllTopicsClicked,
  topicDetailsCollapsed,
  topicDetailsExpanded,
} from "../../../../../analytics/index.analytics";

type TopicDetailsProps = {
  subject?: string;
  topics: MentorshipPlanMentorTopic[];
  index: number;
  openedTopicIndex: number;
  handleOpenedTopicIndex: (index: number) => void;
  defaultAnalyticsPayload?: any;
};

const TopicDetails = ({
  subject,
  topics,
  index,
  openedTopicIndex,
  handleOpenedTopicIndex,
  defaultAnalyticsPayload,
}: TopicDetailsProps) => {
  const [showAllTopicDetails, setShowAllTopicDetails] = useState(false);
  const handleShowAllTopicDetails = () => {
    if (!showAllTopicDetails)
      showAllTopicsClicked({ ...defaultAnalyticsPayload });
    setShowAllTopicDetails(!showAllTopicDetails);
  };

  return (
    <div className="border border-[#333] rounded-lg p-3">
      <div
        className="flex cursor-pointer items-center gap-3"
        onClick={() => {
          if (openedTopicIndex === index) {
            handleOpenedTopicIndex(-1);
            topicDetailsCollapsed({ ...defaultAnalyticsPayload });
          } else {
            handleOpenedTopicIndex(index);
            topicDetailsExpanded({ ...defaultAnalyticsPayload });
          }
        }}
      >
        <img src={ChevronDownIcon} alt="chevron-down.svg" />
        <p className="text-[11px] font-medium text-[#fff]">{subject}</p>
      </div>
      <AnimatePresence>
        {openedTopicIndex === index && (
          <motion.div
            className="overflow-hidden"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <>
              <div className="pl-[34px]">
                <div className="my-4 h-[1px] w-full bg-[#333]" />
              </div>
              <div className="ml-[14px] border-l border-[#333]">
                <div className="space-y-5 pl-5 md:space-y-[22px]">
                  {(showAllTopicDetails ? topics : topics?.slice(0, 5))?.map(
                    (item) => {
                      return (
                        <div
                          className="flex items-center gap-2.5"
                          key={item?.recordId}
                        >
                          <img
                            src={TopicIcon}
                            alt="topic.svg"
                            className="shrink-0"
                          />
                          <p className="text-[10px] text-[#fff]">
                            {item?.topic}
                          </p>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </>
            {topics?.length > 5 ? (
              <div className="mt-4 flex items-center pl-5">
                <div className="h-[1px] flex-1 bg-[#E1E4EA]" />
                <button
                  className="flex gap-1 rounded-[20px] border border-[#E1E4EA] px-2.5 py-1 md:px-3 md:py-1.5"
                  onClick={handleShowAllTopicDetails}
                >
                  <p className="text-[10px] font-medium leading-5 text-[#fff]">
                    {showAllTopicDetails ? "Collapse" : "Show All Topics"}
                  </p>
                  <img
                    src={ChevronDown}
                    alt="chevron-down.svg"
                    className={`${
                      showAllTopicDetails ? "rotate-180" : "rotate-0"
                    } duration-300`}
                  />
                </button>
                <div className="h-[1px] flex-1 bg-[#E1E4EA]" />
              </div>
            ) : (
              <></>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TopicDetails;
