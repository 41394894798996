import { MentorshipPlanMentorAssignments, MentorshipPlanMentorProjects, MentorshipPlanMentorResources, TemplateMonth as TemplateMonthType } from "types/mentorship-plan";

import { useEffect, useState } from "react";
import { getMentorshipPlanMentorAssignments, getMentorshipPlanMentorProjects, getMentorshipPlanMentorResources } from "api/mentorship-curriculm";

type UseAssetsProps = {
  templateMonthData: TemplateMonthType;
};

const useAssets = ({ templateMonthData }: UseAssetsProps) => {
  const [mentorshipPlanResourcesData, setMentorshipPlanResourcesData] = useState<MentorshipPlanMentorResources[]>([])
  const [mentorshipPlanAssignmentsData, setMentorshipPlanAssignmentsData] = useState<MentorshipPlanMentorAssignments[]>([])
  const [mentorshipPlanProjectsData, setMentorshipPlanProjectsData] = useState<MentorshipPlanMentorProjects[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [resources, assignments, projects] = await Promise.all([
          getMentorshipPlanMentorResources({ resource_ids: templateMonthData?.mentorPlanResources?.join(',') }),
          getMentorshipPlanMentorAssignments({ assignment_ids: templateMonthData?.mentorPlanAssignments?.join(',') }),
          getMentorshipPlanMentorProjects({ project_ids: templateMonthData?.mentorPlanProjects?.join(',') }),
        ]);

        setMentorshipPlanResourcesData(resources)
        setMentorshipPlanAssignmentsData(assignments)
        setMentorshipPlanProjectsData(projects)

      } catch (error) {
        console.error("Error fetching mentorship data:", error);
      }
    };
    fetchData();
  }, [])



  return {
    mentorshipPlanResourcesData,
    mentorshipPlanAssignmentsData,
    mentorshipPlanProjectsData,
  };
};

export default useAssets;
