export const CONTENT_V2: any = {
  MENTEE:{
    STEP_1:{
      NAVBAR_CONTENT:{
        HEADING:"Introduction"
      },
      SECTION_1:{
        HEADING:"About Mentor"
      },
      SECTION_2:{
        HEADING:"Skills"
      }
    },
    STEP_2:{
      NAVBAR_CONTENT:{
        HEADING:"Assessment"
      },
      SECTION_1:{
        HEADING:"Interview readiness",
      },
      SECTION_2:{
        HEADING:"Areas of Improvement"
      },
      SECTION_3:{
        HEADING:"Skills"
      },
      SECTION_4:{
        HEADING:"Additional areas of improvement"
      }
    },
    STEP_3:{
      NAVBAR_CONTENT:{
        HEADING:"Planning"
      },
      SECTION_1:{
        HEADING:"How will your mentor help you reach your goal ?"
      }
    }
  },
  MENTOR:{
    STEP_1:{
      NAVBAR_CONTENT:{
        HEADING:"Introduction",
        INACTIVE_CONTENT:"",
        ACTIVE_CONTENT:{
          SECTION_1:"Discuss where your mentee is coming from and the reason they are looking for a job.",
          SECTION_2:"Discuss why they are aiming for the role and company and what motivates them for the same.",
          SECTION_3:"Discuss the difficulties the mentee is facing while trying to achieve the goal and why they are seeking mentorship.",
          SECTION_4:"Discuss how far along the mentee has come in their preparation journey and what skills they have acquired through their past roles.",
        }
      },
      SECTION_1:{
        HEADING:"Background",
        THEME:{
          BG_COLOR:"#EFF8FF",
          TEXT_COLOR:"#175CD3"
        },
        ERROR_MESSAGE:"",
      },
      SECTION_2:{
        HEADING:"Goals",
        THEME:{
          BG_COLOR:"#ECFDF3",
          TEXT_COLOR:"#027A48"
        },
        ERROR_MESSAGE:"",
      },
      SECTION_3:{
        HEADING:"Difficulties faced by mentee",
        THEME:{
          BG_COLOR:"#FFF5F6",
          TEXT_COLOR:"#C01048"
        },
        ERROR_MESSAGE:"",
      },
      SECTION_4:{
        HEADING:"Efforts taken by mentee",
        THEME:{
          BG_COLOR:"#FFF6ED",
          TEXT_COLOR:"#C4320A"
        },
        ERROR_MESSAGE:"",
      }
    },
    STEP_2:{
      NAVBAR_CONTENT:{
        HEADING:"Assessment",
        INACTIVE_CONTENT:"",
        ACTIVE_CONTENT:{
          SECTION_1:"Evaluate the mentees Interview readiness level based of their goals and preparation so far",
          SECTION_2:"Based on their goals and preparation so far, list down what the mentee needs to work on",
          SECTION_3:"Add the skills they lack to achieve the goal and assure them that you can help them with the same.",
          SECTION_4:"Add areas they are lacking in apart from the skills like projects, assignments, and career steps.",
        }
      },
      SECTION_1:{
        HEADING:"Interview Readiness *",
        THEME:{
          BG_COLOR:"#EFF8FF",
          TEXT_COLOR:"#175CD3"
        },
        ERROR_MESSAGE:"Please rate the Interview Readiness of the mentee",
      },
      SECTION_2:{
        HEADING:"Areas of improvement",
        THEME:{
          BG_COLOR:"#F9F5FF",
          TEXT_COLOR:"#6941C6"
        },
        ERROR_MESSAGE:"Please mention the skills the mentee needs to work on",
      },
      SECTION_3:{
        HEADING:"Skills *",
        THEME:{
          BG_COLOR:"#F9F5FF",
          TEXT_COLOR:"#6941C6"
        },
        ERROR_MESSAGE:"Please mention the skills the mentee needs to work on",
      },
      SECTION_4:{
        HEADING:"Additional areas of improvement",
        THEME:{
          BG_COLOR:"#F9F5FF",
          TEXT_COLOR:"#6941C6"
        },
        ERROR_MESSAGE:"",
      }
    },
    STEP_3:{
      NAVBAR_CONTENT:{
        HEADING:"Planning",
        INACTIVE_CONTENT:"",
        ACTIVE_CONTENT:{
          SECTION_1:"Elaborate steps they should take to become interview ready by working on the areas of improvement and how to do the same.",
          SECTION_2:"",
          SECTION_3:"",
          SECTION_4:"Let them know how many sessions (minimum) you would be providing (you can always add more sessions)",
          SECTION_5:"Ask the mentee about the effort and hours they would be putting from their side to become ready",
          SECTION_6:"Set a timeline for the mentee based on above info.The timeline decides how many months IPP the mentee will take with you.",
          SECTION_7:"",
        }
      },
      SECTION_1:{
        HEADING:"How will you be helping your mentee *",
        THEME:{
          BG_COLOR:"#F9F5FF",
          TEXT_COLOR:"#6941C6"
        },
        ERROR_MESSAGE:"Please list the actionable steps for mentee",
      },
      SECTION_2:{
        HEADING:"",
        THEME:{
          BG_COLOR:"",
          TEXT_COLOR:""
        },
        ERROR_MESSAGE:"",
      },
      SECTION_3:{
        HEADING:"",
        THEME:{
          BG_COLOR:"",
          TEXT_COLOR:""
        },
        ERROR_MESSAGE:"",
      },
      SECTION_4:{
        HEADING:"",
        THEME:{
          BG_COLOR:"#EFF8FF",
          TEXT_COLOR:"#175CD3"
        },
        ERROR_MESSAGE:"",
      },
      SECTION_5:{
        HEADING:"",
        THEME:{
          BG_COLOR:"#EFF8FF",
          TEXT_COLOR:"#175CD3"
        },
        ERROR_MESSAGE:"",
      },
      SECTION_6:{
        HEADING:"",
        THEME:{
          BG_COLOR:"#EFF8FF",
          TEXT_COLOR:"#175CD3"
        },
        ERROR_MESSAGE:"",
      },
      SECTION_7:{
        HEADING:"",
        THEME:{
          BG_COLOR:"",
          TEXT_COLOR:""
        },
        ERROR_MESSAGE:"",
      },
    }
  }
}